<template>
    <div>
        <div class="stuContact A4Container flexCloumnCenter">
            <div class="form form1">
                <div class="flexCloumnSingleCenter" style="width: 100%;height: 33.3%;">
                    <div class="content flexStart">
                        <div style="height: 100%;border-right: 1px solid #000;padding: 0 20px;
                        display: flex;flex-direction: column;justify-content: space-evenly;align-items: center;">
                            实<br />{{ type==1?'习':'践'}}<br />单<br />位<br />意<br />见
                        </div>
                        <div class="textIndent2" style="padding: 10px; flex: 1;height:100%;position: relative;">

                            <div class="flexBetween" style="width: calc( 100% - 20px );position: absolute;bottom: 10px;">
                                <div style="text-indent:0;">实{{ type == 1 ? '习' : '践' }}成绩：</div>
                                <div style="text-indent:0;">单位盖章：</div>
                                <div style="text-indent:0;">
                                    年<span style="display:inline-block;width: 20px;height: 10px"></span>
                                    月<span style="display:inline-block;width: 20px;height: 10px"></span>
                                    日
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flexCloumnSingleCenter" style="width: 100%;height: 33.3%;border-bottom: 1px solid #000;">
                    <div class="content flexStart">
                        <div style="height: 100%;border-right: 1px solid #000;padding: 0 20px;
                        display: flex;flex-direction: column;justify-content: space-evenly;align-items: center;">
                            指<br />导<br />教<br />师<br />意<br />见
                        </div>
                        <div class="textIndent2"
                            style="padding: 10px; flex: 1;height:100%;position: relative;word-break: break-all;white-space: pre-wrap;">
                            {{ formData.sp_word }}
                            <div class="flexBetween" style="width: calc( 100% - 20px );position: absolute;bottom: 10px;">
                                <div style="text-indent:0;opacity: 0;">占位符号：</div>
                                <div class="flexStart" style="text-indent:0;">
                                    <div>指导教师：</div>
                                    <img v-if="formData.sp_sign" :src="formData.sp_sign" alt=""
                                        style="width: 100px;max-height: 100px;">
                                    <div v-else style="width: 100px;"></div>
                                </div>
                                <div v-if="!formData.sp_time" style="text-indent:0;">
                                    年<span style="display:inline-block;width: 20px;height: 10px"></span>
                                    月<span style="display:inline-block;width: 20px;height: 10px"></span>
                                    日
                                </div>
                                <div v-if="formData.sp_time" style="text-indent:0;">
                                    {{ formData.sp_time }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flexCloumnSingleCenter" style="width: 100%;height: 33.3%;">
                    <div class="content flexStart">
                        <div style="height: 100%;border-right: 1px solid #000;padding: 0 20px;
                        display: flex;flex-direction: column;justify-content: space-evenly;align-items: center;">
                            学<br />校<br />意<br />见
                        </div>
                        <div class="textIndent2" style="padding: 10px; flex: 1;height:100%;position: relative;">
                            {{ formData.event_desc }}
                            <div class="flexBetween" style="width: calc( 100% - 20px );position: absolute;bottom: 10px;">
                                <div style="text-indent:0;opacity: 0;">占位符号：</div>
                                <div class="flexStart" style="text-indent:0;">
                                    <div>学校盖章：</div>
                                    <div style="width: 100px;"></div>
                                </div>
                                <div style="text-indent:0;">
                                    年<span style="display:inline-block;width: 20px;height: 10px"></span>
                                    月<span style="display:inline-block;width: 20px;height: 10px"></span>
                                    日
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'contact',
    data() {
        return {
            dataover: false,
            userid: '',
            formData: {}
        }
    },
    props: {
        stuInfo: {
            type: Object,
            default: () => {
                return {}
            }
        }, type: {
            type: Number,
            default:1
        }
    },
    mounted() {
        let _this = this
        setTimeout(function () {
            let info = JSON.parse(JSON.stringify(_this.stuInfo))
            if (info.signImg) {
                info.signImg = info.signImg.split("?")[0] + "?x-oss-process=image/resize,w_200"
            }
            _this.formData = info
            // console.log(_this.formData)
        }, 600)
    },
    methods: {}
}
</script>

<style lang="less" type="text/less">
.stuContact .ant-col {
    display: flex;
    padding: 0;
    margin: 0;
}

.stuContact .ant-row {
    width: 100%;
    padding: 0;
    margin: 0;
}
</style>
<style lang="less" type="text/less" scoped>
.stuContact {
    font-size: 20px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 25px 0;

    .title {
        font-size: 20px;
        line-height: 50px;
    }

    .form1 {
        width: calc(100% - 50px);
        height: calc(100% - 0px);
        border: 1px solid #000;

        div {
            font-size: 14px;
            color: #323232;
            box-sizing: border-box;
        }

        .flexCloumnSingleCenter:first-of-type {
            border-bottom: 1px solid #000 !important;
        }

        .content {
            width: 100%;
            height: 100%;
        }

        .a-row {
            //border-top: 1px solid #000;
            border-bottom: 1px solid #000;
        }
    }

    .col-row {
        width: 100%;
        margin: 0;
        padding: 0;
    }
}
</style>
