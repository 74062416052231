<template>
    <div>
        <div class="stuContact A4Container flexCloumnCenter">
            <div class="title">江苏联合职业技术学校南京财经分院</div>
            <div class="title">{{ type == 1 ? '毕业生专业实习评定表' : '社会实践报告' }}</div>

            <div class="form">
                <div class="table" style="height: 100%;">
                    <el-row>
                        <el-col :span="3" class="cell cell50 flexCenter" style="border-left: 0;">学号</el-col>
                        <el-col :span="5" class="cell cell50 flexCenter">{{ formData.stu_code }}</el-col>
                        <el-col :span="3" class="cell cell50 flexCenter">姓名</el-col>
                        <el-col :span="4" class="cell cell50 flexCenter">{{ formData.stu_name }}</el-col>
                        <el-col :span="3" class="cell cell50 flexCenter">专业</el-col>
                        <el-col :span="6" class="cell cellMore flexCenter">{{ formData.zy_name || '-' }}</el-col>
                    </el-row>
                    <el-row>
                        <el-col v-if="type == 1" :span="3" class="cell cellMore flexCenter"
                            style="border-left: 0;">实习单位</el-col>
                        <el-col v-if="type != 1" :span="3" class="cell cellMore flexCenter"
                            style="border-left: 0;">社会实践<br />单位名称</el-col>
                        <el-col :span="12" class="cell cellMore flexCenter">{{ formData.firm_name_new }}</el-col>
                        <el-col v-if="type == 1" :span="3" class="cell cellMore flexCenter">实习时间</el-col>
                        <el-col v-if="type != 1" :span="3" class="cell cellMore flexCenter">社会实践<br />时间</el-col>
                        <el-col :span="6" class="cell cellMore flexCenter">{{ formData.sx_date }}</el-col>
                    </el-row>

                    <el-row class="" style="border-left: 0;width:100%;height: calc( 100% - 100px )">
                        <el-col :span="24" class="cell"
                            style="font-size: unset;width:100%;height: 100%;padding: 10px;border-left: 0;overflow: hidden;box-sizing: border-box;position: relative;">
                            <div id="divCntnrA" style="width:100%;height: 100%;overflow: hidden;">
                                <div id="resizeDivA">
                                    <div class='textIndent2'
                                        v-if='formData.contentArr && formData.contentArr.length > 0'
                                        v-for='(item, index) in formData.contentArr'
                                        style="width: 100%;word-break: break-all;white-space: pre-wrap;">{{ item }}
                                    </div>
                                    <div class='textIndent2' v-else
                                        style="width: 100%;word-break: break-all;white-space: pre-wrap;"> {{
                                        formData.content }}</div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'contact',
    data() {
        return {
            dataover: false,
            userid: '',
            formData: [{}],
        }
    },
    props: {
        stuInfo: {
            type: Object,
            default: () => {
                return {}
            }
        },
        type: {
            type: Number,
            default: 1
        }
    },
    mounted() {
        let _this = this
        setTimeout(function () {
            let info = JSON.parse(JSON.stringify(_this.stuInfo))
            _this.formData = info
            _this.$nextTick(() => {
                // console.log(65,_this.formData)
                _this.resizeA()
            })
        }, 500)
    },
    methods: {
        resizeA() {
            let id1 = 'divCntnrA'
            let id2 = 'resizeDivA'

            let divHeight = document.getElementById(id1).offsetHeight
            let textHeight = document.getElementById(id2).offsetHeight
            // console.log('a', divHeight, textHeight)
            let i = 1
            while (textHeight > divHeight && i < 7) {
                document.getElementById(id2).style.fontSize = `${18 - i}px`
                textHeight = document.getElementById(id2).offsetHeight
                i++

            }
        },
    }
}
</script>

<style lang="less" type="text/less">
.stuContact .ant-col {
    display: flex;
    padding: 0;
    margin: 0;
}

.stuContact .ant-row {
    width: 100%;
    padding: 0;
    margin: 0;
}
</style>
<style scoped="scoped" type="text/less" lang="less">
.flexStretch {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}

.stuContact {
    font-size: 20px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 25px 0;

    .title {
        font-size: 20px;
        //line-height: 50px;
    }

    .form {
        width: calc(100% - 50px);
        height: calc(100% - 50px);
        border: 1px solid #000;
        border-top: 0;

        div {
            //font-size: 14px;
            color: #323232;
            box-sizing: border-box;
        }

        .content {
            width: 100%;
            height: 100%;
            padding: 10px;
            white-space: break-spaces;
            word-break: break-all;
            overflow: hidden;
        }
    }

    .col-row {
        width: 100%;
        margin: 0;
        padding: 0;
    }
}


.stuContact {

    .table {
        width: 100%;
    }

    .borderR {
        border-right: 1px solid #333;
    }

    .borderB {
        border-bottom: 1px solid #333;
    }

    .FS18 {
        font-size: 18px;
    }

    .bold {
        font-weight: 700;
        font-family: Avenir, Helvetica, Arial, sans-serif;
    }

    .cell {
        border-left: 1px solid #333;
        border-top: 1px solid #333;

        min-height: 30px;
        //line-height: 30px;
        //text-align: center;
        white-space: nowrap;
        font-size: 14px;
        font-family: "宋体";
        word-break: break-all;
        white-space: normal;
    }

    .cell50 {
        min-height: 50px;
        line-height: 50px;
        font-size: 14px;
        font-family: "宋体";
    }

    .cellMore {
        border-left: 1px solid #333;
        border-top: 1px solid #333;
        min-height: 50px;
        text-align: left;
        font-size: 14px;
        font-family: "宋体";
    }
}

.uline {
    text-decoration: underline;
}
</style>
